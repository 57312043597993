import type { InferGetStaticPropsType } from 'next'
import Head from 'next/head'
import { Article } from '../app/components/Article'
import { FooterMenu } from '../app/components/FooterMenu'
import { HomePage } from '../app/components/HomePage'
import { combineUrl } from '../app/data/combineUrl'
import { ArticleFragment } from '../app/data/content/ArticleFragment'
import { FooterMenuFragment } from '../app/data/content/FooterMenuFragment'
import { HistoryHomePageFragment } from '../app/data/content/HistoryHomePageFragment'
import { HistoryPeriodTileFragment } from '../app/data/content/HistoryPeriodTileFragment'
import { FooterMenuUnique, OrderDirection, Thunder } from '../generated/content'
import {
	RespondWithNotFound,
	staticPathsHandler,
	staticPropsHandler,
} from '../packages/@necktip/next/api/handlers'
import { getZeusConnection } from '../packages/@necktip/next/loaders/graphql'

export type CommonPageProps = InferGetStaticPropsType<typeof getStaticProps>

export default function CommonPage(props: CommonPageProps) {
	const article = props.getArticle
	const footerMenu = props.getFooterMenu
	const homePage = props.getHistoryHomePage

	return (
		<>
			<Head>
				<title>Historie Respektu</title>
				<link rel="icon" href="/favicon.ico" />
			</Head>
			{homePage && <HomePage homePage={homePage} timePeriods={props.listHistoryPeriod} />}
			{article && <Article article={article} />}
			{footerMenu && <FooterMenu footerMenu={footerMenu} />}
		</>
	)
}

export const getStaticPaths = staticPathsHandler(async () => {
	return {
		fallback: 'blocking',
		paths: [],
	}
})

export const getStaticProps = staticPropsHandler(async (context) => {
	const content = getZeusConnection(Thunder, 'content')

	const urlInfo = combineUrl(context)

	const url = `${urlInfo.path}`.substring(1)
	const data = await content('query')({
		getArticle: [{ by: { url: { url } } }, ArticleFragment()],
		getHistoryHomePage: [{ by: { slug: url } }, HistoryHomePageFragment()],
		listHistoryPeriod: [{ orderBy: [{ order: OrderDirection.asc }] }, HistoryPeriodTileFragment()],
		getFooterMenu: [{ by: { unique: FooterMenuUnique.One } }, FooterMenuFragment()],
	})
	if (!data.getHistoryHomePage && !data.getArticle) {
		throw new RespondWithNotFound('Page not found')
	}
	return {
		props: {
			...data,
		},
		revalidate: 30,
	}
})
