import type { HistoryHomePageResult } from '../data/content/HistoryHomePageFragment'
import type { HistoryPeriodTileResult } from '../data/content/HistoryPeriodTileFragment'
import { Cover } from './Cover'
import { TimePeriodTile } from './HistoryPeriodTile'
import s from './HomePage.module.sass'

export type HomeProps = {
	homePage: HistoryHomePageResult
	timePeriods?: HistoryPeriodTileResult[]
	// personalities?: PersonalityTileResult[]
}

export function HomePage(props: HomeProps) {
	return (
		<div className={s.HomePage}>
			<div className={s.Cover}>
				{props.homePage.cover && <Cover cover={props.homePage.cover} />}
			</div>

			<div className={s.Content}>
				<p className={s.SectionsTitle}>{props.homePage.timePeriodTitle}</p>

				<div className={s.TimePeriodBox}>
					{props.timePeriods?.map((item, i) => (
						<div key={i} className={s.TimePeriodTile}>
							<TimePeriodTile timePeriod={item} />
						</div>
					))}
				</div>
				{/*<p className={s.SectionsTitle}>{props.homePage.personalitiesTitle}</p>*/}
				{/* <div className={s.PersonalitiesBox}>
					{props.personalities
						.filter((item) => item.mainPersonality)
						.map((item, i) => (
							<div key={i} className={s.PersonalityTile}>
								<PersonalityBasicTile personality={item} />
							</div>
						))}
				</div> */}
				{/* <div className={s.PersonalitiesBox}>
					{props.personalities
						.filter((item) => !item.mainPersonality)
						.map((item, i) => (
							<div key={i} className={clsx(s.PersonalityTile, s.PersonalityMinor)}>
								<PersonalityBasicTile personality={item} />
							</div>
						))}
				</div> */}
			</div>
		</div>
	)
}
