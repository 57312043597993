import * as React from 'react'

function Arrow() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23">
			<path
				fill="#fffdfa"
				d="M9 21.722L.336 12c-.448-.53-.448-1.287 0-1.789L9 .461C9.294.181 9.652 0 10 0c1.172 0 1.775 1.373 1 2.224L4.27 9.738l16.406-.028C21.407 9.708 22 10.3 22 11v.152c0 .73-.593 1.322-1.324 1.321L4.27 12.445 11 20c.775.81.172 2.163-1 2.163-.348 0-.706-.163-1-.441"></path>
		</svg>
	)
}

export default Arrow
