import { RichTextRenderer } from '@contember/react-client'
import Image from 'next/image'
import Link from 'next/link'
import type { ArticleResult } from '../data/content/ArticleFragment'
import Arrow from '../svg/icons/Arrow'
import Logo from '../svg/icons/Logo'
import s from './Article.module.sass'
import { ContentRenderer } from './ContentRenderer'
import { linkToTimePeriod } from './linkToTimePeriod'

export type ArticleProps = {
	article: ArticleResult
}

export function Article(props: ArticleProps) {
	return (
		<div className={s.Article}>
			<div className={s.ArticleLogo}>
				<Link href="/">
					<a>
						<Logo />
					</a>
				</Link>
			</div>
			<div className={s.Cover}>
				{props.article.coverPhoto?.image && (
					<div className={s.Image}>
						<Image
							alt=""
							src={props.article.coverPhoto.image.url}
							objectFit="cover"
							layout="fill"
						/>
					</div>
				)}
				<div className={`${s.CoverContent} ${props.article.coverPhoto ? s.WithImage : ''}`}>
					<div className={s.TextBox}>
						<div className={s.LinkReferenceBox}>
							{props.article.historyPeriods.map(
								(item, id) =>
									item.historyPeriod?.slug && (
										<a
											className={s.LinkReference}
											key={id}
											href={linkToTimePeriod(item.historyPeriod?.slug)}>
											{item.historyPeriod?.name}
										</a>
									)
							)}
							{/* {props.article.personalities.map((item, id) => (
								<a className={s.LinkReference} key={id} href={item.link?.url}>
									{item.cover?.title}
								</a>
							))} */}
						</div>
						<h1 className={s.Title}>{props.article.title}</h1>
						<div className={s.LeadParagraph}>
							{props.article.subtitle?.parts.map((item) => (
								<div key={item.id}>
									<RichTextRenderer source={item.json} />
								</div>
							))}
						</div>
					</div>
				</div>
				<p className={s.ImageSource}>
					{props.article.coverPhoto?.title
						? props.article.coverPhoto?.title
						: props.article.coverPhoto?.image?.title}
					{props.article.coverPhoto?.image?.author
						? `, Autor: ${props.article.coverPhoto?.image?.author}`
						: ''}
				</p>
			</div>
			<div className={s.InfoBox}>
				{props.article.authors.map((item, id) => (
					<>
						{item.author?.illustration?.image && (
							<div key={id} className={s.AuthorImage}>
								{item.author?.illustration?.image?.url && (
									<Image
										alt=""
										src={item.author.illustration.image.url}
										objectFit="cover"
										layout="fill"
									/>
								)}
							</div>
						)}
					</>
				))}
				<div className={s.Info}>
					{props.article.historyPeriods[0] && (
						<div className={s.BackArrow}>
							{props.article.historyPeriods[0].historyPeriod?.slug && (
								<Link href={linkToTimePeriod(props.article.historyPeriods[0].historyPeriod?.slug)}>
									<a className={s.BackArrowInner}>
										<Arrow />
									</a>
								</Link>
							)}
						</div>
					)}
					{props.article.authors?.map((item, id) => (
						<p className={s.AuthorName} key={id}>
							{item.author?.name}
						</p>
					))}
					<p className={s.CreatedAt}>
						{new Intl.DateTimeFormat('cs', {
							day: 'numeric',
							month: 'numeric',
							year: 'numeric',
						}).format(new Date(props.article.publishAt))}
					</p>
				</div>
			</div>
			{props.article.content && <ContentRenderer content={props.article.content} />}
		</div>
	)
}
