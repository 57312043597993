import Image from 'next/image'
import type { HistoryPeriodTileResult } from '../data/content/HistoryPeriodTileFragment'
import { linkToTimePeriod } from './linkToTimePeriod'
import s from './TimePeriodTile.module.sass'

export type TimePeriodTileProps = {
	timePeriod: HistoryPeriodTileResult
}

export function TimePeriodTile(props: TimePeriodTileProps) {
	const image = props.timePeriod.cover?.tileImage || props.timePeriod.cover?.coverImage

	return (
		<a href={linkToTimePeriod(props.timePeriod.slug)} className={s.TimePeriodTile}>
			<h3 className={s.Title}>{props.timePeriod.cover?.title}</h3>
			<span className={s.Image}>
				{image?.image?.url && (
					<Image alt="" src={image.image.url} objectFit="cover" layout="fill" />
				)}
			</span>
			<div className={s.LeadParagraphBox}>
				<p className={s.TileParagraph}>{props.timePeriod.cover?.tileParagraph}</p>
				<p className={s.Button}>Přenést do historie</p>
			</div>
		</a>
	)
}
