import * as React from 'react'

function Logo() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="114" height="50" viewBox="0 0 114 50">
			<path d="M92.296 48V23H89v-6h13v6h-3.3v25zM79.521 30.286L82.236 17h6.631l-3.353 15.233L90 48h-7l-3.48-12.751h-.074V48H73V17h6.446v13.286zM61 48V17h11v6h-4.108v6.102h3.707v6.412h-3.707V42H72v6zm-1-24.473v8.133c0 5.34-1.146 6.528-6.543 6.528h-1.914V48H45V17h8.457C58.854 17 60 18.146 60 23.527zm-7 8.318V24.15c0-.78-.398-1.15-1.239-1.15H51v10h.761c.841 0 1.239-.373 1.239-1.155zm-17.193-3.07l4.361 2.57C42.272 32.573 43 34.178 43 36.785v4.656C43 46.697 41.85 48 35.537 48 29.11 48 28 46.697 28 41.441v-4.88h6.543v5.143c0 .78.383.97.958.97.572 0 .955-.19.955-.97V38c0-1.064-.191-1.476-1.262-2.108l-4.362-2.573C28.727 32.09 28 30.488 28 27.88v-4.322C28 18.304 29.15 17 35.462 17 41.89 17 43 18.304 43 23.557v4.546h-6.544v-4.806c0-.781-.383-.968-.955-.968-.575 0-.958.187-.958.968v3.354c0 1.081.193 1.49 1.264 2.124zM16 48V17h11v6h-4.109v6.102h3.706v6.412H22.89V42H27v6zm-4.56-14v.067c3.214.609 3.56 2.707 3.56 6.296V48H8.458V38c0-.765-.386-1.148-1.187-1.148h-.728V48H0V17h8.458C13.85 17 15 18.146 15 23.527v4.162c0 3.476-.346 5.726-3.56 6.311zM8 29.807V23.19C8 22.383 7.597 22 6.76 22H6v9h.76C7.598 31 8 30.617 8 29.807z"></path>
			<text
				dominantBaseline="text-before-edge"
				style={{ fontKerning: 'normal' }}
				fill="#a5a5a5"
				fontFamily="'Despekt','Despekt'"
				fontSize="14"
				letterSpacing="0.54">
				<tspan style={{ fontKerning: 'normal' }}>HISTORIE.</tspan>
			</text>
			<text
				dominantBaseline="text-before-edge"
				style={{ fontKerning: 'normal' }}
				fill="#a5a5a5"
				fontFamily="'Despekt','Despekt'"
				fontSize="10"
				letterSpacing="0.38"
				transform="translate(101 38)">
				<tspan style={{ fontKerning: 'normal' }}>.CZ</tspan>
			</text>
		</svg>
	)
}

export default Logo
